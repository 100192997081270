import { render, staticRenderFns } from "./index.vue?vue&type=template&id=1c705a5e&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {UiButton: require('/codebuild/output/src3180435009/src/med-el-hearpeers/components/Ui/Button/index.vue').default,AvatarToolIntro: require('/codebuild/output/src3180435009/src/med-el-hearpeers/components/AvatarTool/Intro.vue').default,AvatarToolUpload: require('/codebuild/output/src3180435009/src/med-el-hearpeers/components/AvatarTool/Upload.vue').default,AvatarToolCrop: require('/codebuild/output/src3180435009/src/med-el-hearpeers/components/AvatarTool/Crop.vue').default,AvatarToolCreate: require('/codebuild/output/src3180435009/src/med-el-hearpeers/components/AvatarTool/Create.vue').default,AvatarToolFinish: require('/codebuild/output/src3180435009/src/med-el-hearpeers/components/AvatarTool/Finish.vue').default})
