//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Cropper } from 'vue-advanced-cropper'

export default {
  components: {
    Cropper,
  },
  props: {
    avatarImage: { type: String, default: null },
  },
  methods: {
    crop() {
      const { canvas } = this.$refs.cropper.getResult()
      if (canvas) {
        const image = canvas.toDataURL()
        this.$emit('upload', image)
        // canvas.toBlob((image) => {
        //   this.$emit('upload', image)
        // })
      }
    },
    cancel() {
      this.$emit('cancel')
    },
  },
}
