//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import share from '~/mixins/share.js'

export default {
  mixins: [share],
  props: {
    author: {
      type: Object,
      default() {},
    },
    reactions: {
      type: Number,
      default: 0,
    },
    likedStatus: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      showLikeTooltip: false,
    }
  },
  methods: {
    onLike() {
      if (!this.$auth.user) {
        this.showLikeTooltip = true
        return
      }
      this.showLikeTooltip = false
      this.$emit('updateLikes')
    },
  },
}
