//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    loadImage: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      dragging: false,
    }
  },
  methods: {
    cancel() {
      this.$emit('cancel')
    },
  },
}
