//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    fabric: {
      type: Object,
      required: true,
    },
    hasActiveElement: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      assets: { lime: [], fuchsia: [], emerald: [], red: [], white: [] },
      assetColor: 'white',
    }
  },
  computed: {
    colors() {
      const colors = {}
      for (const [key, value] of Object.entries(
        this.$store.state.global.themes
      )) {
        colors[key] = value.color
      }
      return colors
    },
    bgColors() {
      const { red, white, ...bgColors } = this.colors
      return bgColors
    },
    elementColors() {
      return this.colors
    },
  },
  mounted() {
    // resize canvas
    // @todo resize canvas on init...
    window.addEventListener('load', this.resizeCanvas)
    window.addEventListener('resize', this.resizeCanvas)

    // import assets
    this.importAssets()
  },
  methods: {
    resizeCanvas() {
      this.$emit('resizeCanvas', this.$refs.canvasOuter)
    },
    importAssets() {
      const categoryColors = {
        lime: 'lime',
        fuchsia: 'purple',
        emerald: 'green',
        red: 'red',
        white: 'bw',
      }
      const files = require.context('~/static/avatar/', true, /.*/).keys()
      for (const [category, color] of Object.entries(categoryColors)) {
        const regex = new RegExp(color.toUpperCase() + '.svg$')
        this.assets[category] = files
          // filter by color
          .filter((path) => path.match(regex))
          // remove . at start of filepath
          .map((path) => path.substring(1))
          .map((file) => {
            return { data: '/avatar' + file }
          })
      }
    },
    dragStart(event) {
      event.target.classList.add('dragging')
    },
    dragEnd(event) {
      event.target.classList.remove('dragging')
    },
    drop() {
      this.$emit('drop')
    },
    saveImage() {
      this.$emit('saveImage', this.$refs.fabricCanvas)
    },
    changeBackground(value) {
      this.$emit('changeBackground', value)
    },
    changeElements(value) {
      this.assetColor = value
    },
    bringToFront(value) {
      this.$emit('bringToFront', value)
    },
    flipHorizontal(value) {
      this.$emit('flipHorizontal', value)
    },
    flipVertical(value) {
      this.$emit('flipVertical', value)
    },
    deleteElement() {
      this.$emit('deleteElement')
    },
    cancel() {
      this.$emit('cancel')
    },
  },
}
