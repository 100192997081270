//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { API } from 'aws-amplify'
import { fabric } from 'fabric/dist/fabric.min.js'
import 'vue-advanced-cropper/dist/style.css'
let $fabricCanvas = {}

export default {
  props: {
    showIntro: {
      type: Boolean,
      default: true,
    },
    uploadOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      gcmsId: this.$route.query?.mentorid ?? null,
      email: this.$auth?.user?.email ?? null,
      currentStatus: -1,
      fabric,
      avatarImage: null,
      uploadFieldName: 'fileUpload',
      hasActiveElement: false,
      avatarUrl: '',
      status: {
        INITIAL: -1,
        UPLOAD: 0,
        CROPPING: 1,
        CREATING: 2,
        LOADING: 3,
        FINISHED: 9,
      },
    }
  },
  computed: {
    isInitial() {
      return this.currentStatus === this.status.INITIAL
    },
    isUpload() {
      return this.currentStatus === this.status.UPLOAD
    },
    isCropping() {
      return this.currentStatus === this.status.CROPPING
    },
    isCreating() {
      return this.currentStatus === this.status.CREATING
    },
    isLoading() {
      return this.currentStatus === this.status.LOADING
    },
    isFinished() {
      return this.currentStatus === this.status.FINISHED
    },
  },
  mounted() {
    $fabricCanvas = new fabric.Canvas('fabricCanvas', {
      hoverCursor: 'default',
      uniScaleKey: 'none',
    })

    $fabricCanvas.on('selection:cleared', () => {
      this.hasActiveElement = !!$fabricCanvas.getActiveObject()
    })
    $fabricCanvas.on('selection:created', () => {
      this.hasActiveElement = !!$fabricCanvas.getActiveObject()
    })

    if (this.showIntro) {
      this.setStatus(this.status.INITIAL)
    } else {
      this.reset()
    }
  },
  methods: {
    setStatus(status) {
      this.currentStatus = status
    },
    reset() {
      this.setStatus(this.status.UPLOAD)
    },
    drop() {
      const droppedElement = document.querySelector('img.asset.dragging')
      if (droppedElement) {
        const target = droppedElement
        fabric.Image.fromURL(
          target.src,
          function (img) {
            img.set({
              centeredScaling: true,
              centeredRotation: true,
              scaleY: 1,
              scaleX: 1,
              lockSkewingX: true,
              lockSkewingY: true,
              cornerSize: 6,
              borderDashArray: [2, 2],
              borderColor: '#B1DDFF',
              hoverCursor: 'move',
              originY: 'center',
              originX: 'center',
              top: $fabricCanvas.height / 2,
              left: $fabricCanvas.width / 2,
              angle: 0,
            })
            // only original aspect ratio allowed!
            img.setControlsVisibility({
              mb: false,
              ml: false,
              mr: false,
              mt: false,
            })
            $fabricCanvas.add(img)
          },
          { crossOrigin: 'Anonymous' }
        )
      }
    },
    addToCanvas(response) {
      fabric.Image.fromURL(
        response.Location,
        function (oImg) {
          oImg.set({
            selectable: true,
          })
          const filter = new fabric.Image.filters.Grayscale()
          oImg.filters.push(filter)
          oImg.applyFilters()
          $fabricCanvas.add(oImg)
        },
        { crossOrigin: 'Anonymous' }
      )
    },
    saveImage(fabricCanvasRef) {
      $fabricCanvas.discardActiveObject().renderAll()
      const canvasImage = fabricCanvasRef.toDataURL()
      this.uploadAndSaveImage(canvasImage)
    },

    loadImage(event) {
      this.setStatus(this.status.LOADING)
      const { files } = event.target
      if (files && files[0]) {
        if (this.avatarImage) {
          URL.revokeObjectURL(this.avatarImage)
        }
        this.avatarImage = URL.createObjectURL(files[0])
        this.setStatus(this.status.CROPPING)
      }
    },

    // first, upload the cropped image
    uploadCroppedImage(blob) {
      // const formData = this.initUpload(blob)
      // ...than remove background from it
      if (!this.uploadOnly) {
        this.removeBackgroundFromImage(blob).then((response) => {
          this.setStatus(this.status.CREATING)
          // ...add the backgroundless image to canvas
          this.addToCanvas(response)
        })
      } else {
        this.uploadAndSaveImage(blob)
      }
    },

    // after creating the avatar, upload it to the s3 bucket
    async uploadAndSaveImage(blob) {
      const response = await API.post('api', '/rmbg/saveAvatarImage', {
        body: { image: blob },
      })
      this.$store.commit('user/setAvatarUrl', response.location)
      this.avatarUrl = response.location
      this.setStatus(this.status.FINISHED)
    },

    async removeBackgroundFromImage(formData) {
      const response = await API.post('api', '/rmbg', {
        body: { image: formData },
      })
      return response
    },

    async saveAvatarJson(email, json, gcmsId = null) {
      await API.post('api', '/mentor/saveAvatarProps', {
        body: { email, gcmsId, json, url: this.avatarUrl },
      })
    },

    setProfileImage() {
      this.saveAvatarJson(
        this.email,
        JSON.stringify($fabricCanvas),
        this.gcmsId
      )
      this.setStatus(this.status.INITIAL)
      this.cancel()
    },

    /* color change routine */
    changeBackground(value) {
      $fabricCanvas.backgroundColor = value
      $fabricCanvas.renderAll()
    },

    /* action buttons */
    flipHorizontal() {
      const flip = $fabricCanvas.getActiveObject().flipX
      $fabricCanvas.getActiveObject().set('flipX', !flip)
      $fabricCanvas.renderAll()
    },
    flipVertical() {
      const flip = $fabricCanvas.getActiveObject().flipY
      $fabricCanvas.getActiveObject().set('flipY', !flip)
      $fabricCanvas.renderAll()
    },
    bringToFront() {
      $fabricCanvas.getActiveObject().bringToFront()
      $fabricCanvas.renderAll()
    },
    duplicate() {
      const clonedObject = fabric.util.object.clone(
        $fabricCanvas.getActiveObject()
      )
      clonedObject.set('top', $fabricCanvas.getActiveObject().top + 10)
      clonedObject.set('left', $fabricCanvas.getActiveObject().left + 10)
      $fabricCanvas.add(clonedObject)
      $fabricCanvas.renderAll()
    },
    deleteElement() {
      $fabricCanvas.getActiveObjects().forEach((element) => {
        $fabricCanvas.remove(element)
      })
    },
    /* routines end */

    destroyed() {
      if (this.avatarImage.src) {
        URL.revokeObjectURL(this.avatarImage.src)
      }
    },

    cancel() {
      this.$store.commit('global/setDialog', {
        dialog: null,
        layer: false,
      })
    },

    resizeCanvas(canvasOuter) {
      const ratio = $fabricCanvas.getWidth() / $fabricCanvas.height
      const containerWidth = canvasOuter.clientWidth
      const scale = containerWidth / $fabricCanvas.getWidth()
      const zoom = $fabricCanvas.getZoom() * scale

      $fabricCanvas.setDimensions({
        width: containerWidth,
        height: containerWidth / ratio,
      })
      $fabricCanvas.setViewportTransform([zoom, 0, 0, zoom, 0, 0])
    },
  },
}
