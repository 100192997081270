//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    width: {
      type: [Number, String],
      default: 110,
    },
    height: {
      type: [Number, String],
      default: 110,
    },
  },
}
