//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import share from '~/mixins/share.js'
export default {
  mixins: [share],
  props: {
    avatarUrl: {
      type: String,
      required: true,
    },
  },
  computed: {
    shareData() {
      return {
        title: 'Profile Image',
        url: this.avatarUrl,
      }
    },
  },
  methods: {
    restart() {
      this.$emit('restart')
    },
    setProfileImage() {
      this.$emit('setProfileImage')
    },
  },
}
