//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import articleStats from '~/mixins/articleStats.js'
import layout from '~/mixins/layout'
import { mobileBreakpoint } from '~/utils/breakpoints.js'

export default {
  mixins: [articleStats, layout],
  props: {
    title: {
      type: String,
      default: '',
      required: true,
    },
    author: {
      type: String,
      default: null,
    },
    updatedAt: {
      type: String,
      default: '0',
    },
    image: {
      type: Object,
      default() {},
    },
    imageMobile: {
      type: Object,
      default() {},
    },
    tags: {
      type: Array,
      default: null,
    },
  },

  data() {
    return {
      innerWidth: window.innerWidth,
    }
  },

  computed: {
    meta() {
      const author = this.author ? this.author : 'Hearpeers'
      const time =
        '<time datetime="' +
        this.$datetime(this.updatedAt) +
        '">' +
        this.$dayjs(this.updatedAt)
          .locale(this.$i18n.localeProperties.iso)
          .format('ll') +
        '</time>'

      const template =
        '<p>' +
        (this.author
          ? this.$translation('article.meta', { author, time })
          : this.$translation('article.meta.lastUpdate', { time })) +
        '</p>'

      return { template }
    },
    imageDependingOnDeviceWidth() {
      let src = this.image
      if (this.imageMobile && this.innerWidth <= mobileBreakpoint) {
        src = this.imageMobile
      }
      return this.backgroundImage(src)
    },
  },

  mounted() {
    window.addEventListener('resize', this.resizeCallback)
  },
  unMounted() {
    window.removeEventListener('resize', this.resizeCallback)
  },

  methods: {
    resizeCallback() {
      this.innerWidth = window.innerWidth
    },
  },
}
