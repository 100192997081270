//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { API } from 'aws-amplify'
import proseText from '~/mixins/proseText'
import layout from '~/mixins/layout'

export default {
  mixins: [proseText, layout],
  inheritAttrs: false,
  props: {
    title: {
      type: String,
      required: true,
    },
    updatedAt: {
      type: String,
      default: '',
    },
    author: {
      type: Object,
      default() {},
    },
    image: {
      type: Object,
      default() {},
    },
    imageMobile: {
      type: Object,
      default() {},
    },
    content: {
      type: Object,
      default() {},
    },
    id: {
      type: String,
      required: true,
    },
    tags: {
      type: Array,
      default() {},
    },
    topics: {
      type: Array,
      default() {},
    },
  },
  data() {
    return {
      reactions: null,
      likedStatus: 'loading',
    }
  },
  async fetch() {
    const response = await API.post('api', '/activityFeatures/getPostLikes', {
      body: {
        postId: this.id,
      },
    })

    this.reactions = response.data?.length ?? 0

    const userEmail = this.$auth.user?.email ?? null
    const isLikedByUser =
      response.data?.some((like) => like.userEmail === userEmail) ?? false
    this.likedStatus = isLikedByUser ? 'is-liked' : 'not-liked'
  },
  computed: {
    readingTime() {
      return this.$readingTime(this.content?.text)
    },
  },
  methods: {
    async onUpdateLikes() {
      switch (this.likedStatus) {
        case 'loading':
          return
        case 'is-liked':
          this.likedStatus = 'not-liked'
          this.reactions--
          break
        case 'not-liked':
          this.likedStatus = 'is-liked'
          this.reactions++
          break
      }

      await API.post('api', '/activityFeatures/like', {
        body: {
          isLike: this.likedStatus === 'is-liked',
          postId: this.id,
          userEmail: this.$auth.user?.email ?? null,
          reactionTimestamp: Date.now(),
        },
      })
      this.$fetch()
    },
  },
}
