/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cloud_logic_custom": [
        {
            "name": "api",
            "endpoint": "https://9ie86hkz3d.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        },
        {
            "name": "forum",
            "endpoint": "https://tyxx7870og.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        },
        {
            "name": "ses",
            "endpoint": "https://htdtxiol3d.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        }
    ],
    "aws_cognito_identity_pool_id": "eu-central-1:d79e3173-949c-45ab-a8bc-05ce7ab60f04",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_Y8SBU3ZTK",
    "aws_user_pools_web_client_id": "2i1dp9s50v7esm1ji6par1r4dk",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_dynamodb_all_tables_region": "eu-central-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "contactForm-prod",
            "region": "eu-central-1"
        },
        {
            "tableName": "hearpeersmentordata-prod",
            "region": "eu-central-1"
        },
        {
            "tableName": "hearpeersmentors-prod",
            "region": "eu-central-1"
        }
    ],
    "aws_user_files_s3_bucket": "hearpeers-avatars111040-prod",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
